<template>
<div class="data-area">
  <div class="filter-area">
    <Form :model="filter" :label-width="60" inline>
      <FormItem label="设备ID">
        <Input v-model="filter.id" clearable placeholder="设备ID" style="width: 100px" />
      </FormItem>
      <FormItem label="ICCID">
        <Input v-model="filter.iccid" clearable placeholder="ICCID" style="width: 200px" />
      </FormItem>
      <FormItem label="IMEI">
        <Input v-model="filter.imei" clearable placeholder="IMEI" style="width: 150px" />
      </FormItem>
      <!-- <FormItem label="更新时间" :label-width="70">
        <DatePicker type="date" v-model="filter.start" format="yyyy-MM-dd" style="width: 115px"></DatePicker>
      </FormItem>
      <FormItem label="至" :label-width="20">
        <DatePicker type="date" v-model="filter.end" format="yyyy-MM-dd" style="width: 115px"></DatePicker>
      </FormItem> -->
      <FormItem :label-width="0">
        <Button type="primary" @click="search" :loading="loading" style="margin-right: 10px">查询</Button>
        <Button type="success" @click="exportList" :loading="loading">导出</Button>
        <!-- <Button type="info" @click="getCount" style="margin-left: 10px" :loading="loading">数量</Button> - {{count}} -->
      </FormItem>
    </Form>
  </div>
  <div class="list-area">
    <vxe-table empty-text="无数据" border resizable show-overflow show-header-overflow :data="list" height="auto" :row-config="{isHover: true}">
      <vxe-column type="seq" width="60" title="序号" fixed="left"></vxe-column>
      <vxe-column field="id" title="设备ID" width="150" header-align="center"></vxe-column>
      <vxe-column field="iccid" title="ICCID" header-align="center" width="200"></vxe-column>
      <vxe-column field="imei" title="IMEI" header-align="center" width="200"></vxe-column>
      <vxe-column field="hardware" title="硬件版本" header-align="center"></vxe-column>
      <vxe-column field="software" title="软件版本" header-align="center"></vxe-column>
      <vxe-column field="appcode" title="应用编号" header-align="center" width="110"></vxe-column>
      <vxe-column field="version" title="应用版本号" header-align="center" width="110"></vxe-column>
      <vxe-column field="custName" title="所在组织" width="200" header-align="center"></vxe-column>
      <vxe-column field="create" title="创建时间" header-align="center" width="150"></vxe-column>
      <vxe-column field="update" title="更新时间" header-align="center" width="150">
        <template #default="{ row }">
          <span :style="{color: `${row.update == row.create ? '' : 'blue'}`}">{{row.update}}</span>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalTransLogs',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('trans', ['connections']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  data(){
    return {
      showModal: this.value,
      showDebugModal: false,
      loading: false,
      filter: {
        id: '',
        iccid: '',
        imei: '',
        index: 1,
        size: 20,
      },
      list: [],
      total: 0,
      showEditModal: false,
      editItem: {},
    }
  },
  created: function () {
  },
  mounted: function(){
    this.getList();
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  destroyed: function(){
    // ws.stop();
  },
  methods: {
    search: function(){
      this.filter.index = 1;
      this.getList();
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    getList(){
      this.loading = true;
      this.$axios.post(`station/search/QuerySimcard`, this.filter).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data.list);
          this.total = res.data.count;
        }
      });
    },
    exportList: function(){
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      this.exportLoading = true;
      this.$store.dispatch('auth/reqFile', {
        title: 'ICCID记录数据.xlsx',
        url: `station/search/ExportSimcard`,
        args: this.filter,
      }).then(() => {
        this.exportLoading = false;
      });
    },
    handleRefresh: function(){
    },
    cancel: function(){
      this.showModal = false;
    }
  },
}
</script>
<style scoped>
.show-protocol{
  cursor: pointer;
}
.data-area{
  /* border: solid 1px red; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.filter-area{
  height: 40px;
  flex: none;
}
.list-area{
  height: 400px;
  flex: auto;
}
</style>